import { render, staticRenderFns } from "./diypackage.vue?vue&type=template&id=511aa070&scoped=true&"
import script from "./diypackage.js?vue&type=script&lang=js&"
export * from "./diypackage.js?vue&type=script&lang=js&"
import style0 from "./diypackage.scss?vue&type=style&index=0&id=511aa070&lang=scss&scoped=true&"
import style1 from "./diypackage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511aa070",
  null
  
)

export default component.exports