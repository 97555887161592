/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:37:57
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 17:08:02
 */
import { getNormalWeek, numberFormat } from '@/plugins/utils'
import moment from 'moment'
import EnumMap from '@/plugins/enumMap'
import { mapGetters } from 'vuex'
import CheckStatus from '@/plugins/checkStatus'
export default {
  name: 'Diypackage',
  data () {
    return {
      queryForm: {
        create_status: 0,
        source_type: 0,
        package_id: '',
        field_type: '',
        sort_type: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'enum_map',
      'userInfo'
    ])
  },
  created () {
    this.date = getNormalWeek()
  },
  methods: {
    numFormat (val) {
      return numberFormat(val)
    },
    async Export (value) {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        const payload = Object.assign({}, {
          package_id: [value.id]
        })
        await this.$wExport('/app/datapackage/diy/export.do', payload, value.name + '-' + moment(new Date()).format('YYYYMMDD') + '.txt')
      }
    },
    crowd (row) {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        this.$store.dispatch('App/Datapackage/Crowdattribute/updatePageTitle', `人群包名称：${row.name}`, { root: true })
        this.$router.push({ path: '/app/datapackage/crowdattribute', query: { package_id: row.id } })
      }
    },
    Detail (id) {
      this.$router.push({ path: `/app/datapackage/datapackagedetail/${id}` })
    },
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    tableSort (val) {
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    },
    activityStatus (val) {
      const name = new EnumMap('package_status').mapping(val)
      return name
    },
    Delete (id) {
      const self = this
      self.$confirm('您确认删除此人群包吗？', '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'w-table-delete'
      }).then(async () => {
        self.$refs['w-table'].updateLoading(true)
        const payload = Object.assign({}, {
          id
        })
        const data = await self.$wPost('/app/datapackage/diy/delete.do', payload)
        if (data === true) {
          self.$wToast.success('删除成功')
          self.tableSearch(true)
        } else {
          self.$refs['w-table'].updateLoading(false)
        }
      }).catch(() => {

      });
    },
    handleCommand (e) {
      console.log(e)
      switch (e.type) {
        case 'test':
          // this.test(e.id)
          break;
        case 'delete':
          // this.delete(e.id)
          break;
        default:
          break;
      }
    }
  }
}
